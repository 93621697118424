import { ref } from 'vue'
import { delay } from '~/utils/helpers'
import { cloneDeep, debounce } from 'lodash-es'
import CourseResponse = models.server.api.international.course.CourseResponse
import AggregatedCourses = models.server.api.international.predictive.AggregatedCourses
import ApiQueryBody = models.server.api.international.ApiQueryBody
import ApiResponse = models.server.api.international.ApiResponse
import Course = models.server.api.international.course.Course
import Package = models.server.api.international.course.Package
import SimpleCourse = models.server.api.international.course.SimpleCourse
import CampusByYear = models.server.api.international.course.CampusByYear

// enums
enum ApiSearch {
  Cache = 'is-cache-filters',
  Search = 'is-search-courses-v1.1',
  Suggest = 'is-suggest-courses-v1.0',
  Url = '/api/international/course'
}

export interface CourseDetails {
  id: string
  packageId?: string
  title: string
  helpText: string
  classification?: string
  cricosCode: string
  duration: string
  nationalCode: string
  subjectArea: string
  courses?: Array<SimpleCourse>
  campusByYearSydneyInsideList?: Array<CampusByYear>
  campusByYearSydneyOutsideList?: Array<CampusByYear>
}

// summary
// this composable uses an object to store filters for ease of modifying crud operations
// this object is mapped to an array to be used for dumb components like chips
// the filter is mapped to a data shape usable by the api. It was decided to separate this logic so that if
// changes happen to api it won't affect the components
// the two key api calls are to get courses or course summary results for search only purposes

// interfaces of the composable int prefix for international

interface IntFilter {
  [key: string]: { value: Array<string>; last?: boolean } // eg qualification?: { value: Array<string>; last?: boolean }
}

interface IntClientFilter {
  field: string
  value: string
  last?: true
}

type FilterTree = Record<string, Record<string, boolean>>

// we export the logic as a composable
export const useIntCourseSearch = () => {
  const defaultFilterTree: FilterTree = {
    location: {},
    subjectArea: {},
    courseType: {
      'TAFE NSW Courses': false,
      'TAFE NSW Vocational Pathways': false,
      'TAFE NSW Degree Pathways': false,
      'University Degree Pathways': false
    },
    qualification: {
      'Bachelor Degree': false,
      'Associate Degree': false,
      'Advanced Diploma': false,
      Diploma: false,
      'Certificate IV': false,
      'Certificate III': false
    }
  }

  const courseTypeClassifications = [
    'tafe-nsw-course',
    'vocational-pathway',
    'tafe-degree-pathway',
    'university-degree-pathway'
  ]

  const clientFilterKeys = ['courseType']

  // reactive variables we can use in our components in alphabetical order

  // aggregation used to show users how many results they could have
  const courseSearchTotal = ref<number>(0)

  // bodyRequest is the initial body request setup for the api
  const bodyRequest = ref<ApiQueryBody>({
    id: ApiSearch.Search,
    params: {
      from: 0,
      size: 500
    }
  })

  // bodyRequest is the initial body request setup for the api
  const bodyRequestSummary = ref<ApiQueryBody>({
    id: ApiSearch.Suggest,
    params: {
      from: 0,
      size: 50
    }
  })

  // breadcrumbs are used for course search page
  const breadcrumbs = ref([
    {
      text: 'International',
      to: '/international'
    },
    {
      text: 'Course search',
      to: '/international/course-search'
    }
  ])

  // courses is full detail and used for cards
  const courses = ref<CourseDetails[]>([])

  // coursesSummary is used for searchbar where we show the user a list of options
  // it is summarised to the name and the id
  const coursesSummary = ref<{ name: string; id: string }[]>([])

  // courseCampus is a single location a user selects
  const courseCampus = ref('Any location')

  // errorCoursesApi
  const errorCoursesApi = ref<string | undefined>()

  // this is the most important variable in the composable.
  // it creates a tree of all the filters, to simplify all crud operations
  const filtersTree = ref<FilterTree>(cloneDeep(defaultFilterTree))

  const loadingCourses = ref(true)

  // queryString is when the user types in a value
  const queryString = ref<string>('')

  // retries is used for apiCalls
  const retries = ref<number>(3)

  // computed values we can use in our components

  // mappedFilters is used in our components and takes our core filters tree and maps it to an array.
  // See the international filter chips test page
  const mappedFilters = computed(() => {
    const tree = filtersTree.value
    const filtersArray: Array<IntClientFilter> = []
    Object.keys(tree).forEach((field: string) => {
      Object.keys(tree[field]).forEach((value) => {
        if (tree[field][value]) filtersArray.push({ field, value })
      })
    })

    return filtersArray
  })

  // this maps the filter tree to the correct shape for the api to consume.
  // this has been done separately so that changes to the api can be accommodated
  // without making changes to the components or composables

  const filtersForApi = computed(() => {
    const tree = filtersTree.value
    const fieldObj: IntFilter = {}
    const filterArray: IntFilter[] = []

    // first step is combine all the field values into a IntFilter shape
    Object.keys(tree).forEach((field: string) => {
      // Skip adding client side filters to API request.
      if (clientFilterKeys.includes(field)) return

      Object.keys(tree[field]).forEach((value) => {
        if (tree[field][value]) {
          if (!fieldObj[field]) fieldObj[field] = { value: [], last: false }
          fieldObj[field].value.push(value)
        }
      })
    })

    // we set up a lastField to index the final item in the filterArray
    let lastField

    // secondly we take the consolidated fields, ie location, and we push that to an array
    Object.keys(fieldObj).forEach((field: string) => {
      const consolidated: IntFilter = {}
      consolidated[field] = fieldObj[field]
      filterArray.push(consolidated)
      lastField = field
    })

    // finally we take the last item from the array and using the lastField, set last = true for elastic parsing
    if (lastField) filterArray[filterArray.length - 1][lastField].last = true
    return filterArray
  })

  // reachedEnd checks whether we have loaded all the courses. Currently used for infinite scroll
  const reachedEnd = computed(() => {
    if (!courseSearchTotal.value) return false
    return courseSearchTotal.value <= courses.value.length
  })

  // methods in alphabetical order

  // apiCall local only method, includes a retry method
  const apiCall = async () => {
    errorCoursesApi.value = undefined
    if (filtersForApi.value)
      bodyRequest.value.params.filters = filtersForApi.value
    bodyRequest.value.params.query_string = queryString.value || ''
    let apiResponse
    while (retries.value > 0) {
      try {
        // api call
        apiResponse = await $fetch<ApiResponse>(ApiSearch.Url, {
          method: 'POST',
          body: bodyRequest.value
        })
        break
      } catch (e) {
        retries.value = retries.value - 1
        await delay(2000)
      }
    }
    retries.value = 3
    if (!apiResponse) errorCoursesApi.value = 'service unavailable'
    return apiResponse
  }

  // apiMap to convert api response to our needed shape. Is seperated as mapping may change, and may potentially add a mapping selection process.
  const apiMap = (apiResponse: ApiResponse | undefined) => {
    const data = apiResponse?.hits?.hits as CourseResponse[]
    const mapped =
      data?.map((r) => ({
        ...r._source,
        id: r._id
      })) || []
    const aggregate = apiResponse?.hits?.total?.value || 0
    return { mapped, aggregate }
  }

  const mapCourseToDetails = (course: Course, pkg?: Package): CourseDetails => {
    // Grab title from the last entry in the package course list.

    let title = course.title
    let nationalCode = course.nationalCode
    if (pkg) {
      const highestQualPackage =
        pkg.courseSimpleList[pkg.courseSimpleList.length - 1]

      if (highestQualPackage) {
        title = highestQualPackage.title
        nationalCode = highestQualPackage.id
      }
    }

    return {
      id: course.id,
      packageId: pkg?.packageId,
      title,
      helpText: '', // TODO: Where does this come from?
      cricosCode: course.cricosCode,
      duration: pkg ? pkg.duration : course.duration,
      nationalCode,
      subjectArea: course.subjectArea,
      classification: pkg ? pkg?.classification : 'tafe-nsw-course',
      courses: pkg?.courseSimpleList,
      campusByYearSydneyInsideList: course.campusByYearSydneyInsideList,
      campusByYearSydneyOutsideList: course.campusByYearSydneyOutsideList
    }
  }

  // fetchCourses accumulates responses from the api and adds them to existing results
  const fetchCourses = debounce(async (reset: boolean) => {
    bodyRequest.value.id = ApiSearch.Search
    // Reset courses array and tell the client we're loading.
    courses.value = []
    courseSearchTotal.value = 0
    loadingCourses.value = true
    const packageIds: Set<string> = new Set<string>()

    // Grab items in batches of 500 per API call. Hard cap maximum of 1500 items.
    let totalItems = 0
    const maxItems = 1500
    do {
      try {
        const response = await apiCall()

        updatePagination(500, totalItems)
        const { mapped, aggregate } = apiMap(response)
        courseSearchTotal.value = aggregate

        // Split the mapped entries by the packages within.
        for (const course of mapped) {
          // Map every coursePackage that exists within the item.
          if (course.coursePackage?.length > 0) {
            for (const pkg of course.coursePackage) {
              const details = mapCourseToDetails(course, pkg)

              if (
                details.classification &&
                details.packageId &&
                !packageIds.has(details.packageId)
              ) {
                courses.value.push(details)
                packageIds.add(details.packageId)
              }
            }
          }

          // Map the course only if course id is valid and is NOT just a numeric.
          if (course.id && !parseFloat(course.id)) {
            courses.value.push(mapCourseToDetails(course))
          }
        }
        totalItems += mapped.length
      } catch {
        loadingCourses.value = false
        break
      }
    } while (
      totalItems < courseSearchTotal.value &&
      totalItems < maxItems &&
      !errorCoursesApi.value
    )

    // Mark loading complete.
    loadingCourses.value = false
  }, 150)

  const fetchSubjectAreasLocations = async () => {
    bodyRequest.value.id = ApiSearch.Cache

    const apiResponse = await $fetch<ApiResponse>(ApiSearch.Url, {
      method: 'POST',
      body: bodyRequest.value
    })

    const data = apiResponse.aggregations as AggregatedCourses | undefined
    const subjectAreas = ref<string[]>([])
    const locations = ref<string[]>([])

    subjectAreas.value =
      data?.subjectArea?.buckets
        .map((r) => r.key)
        .filter((key) => key.length > 1) || []

    locations.value =
      data?.location?.buckets
        .map((r) => r.key)
        .filter((key) => key.length > 1) || []

    return { subjectAreas: subjectAreas.value, locations: locations.value }
  }

  // key difference here is the fetchCoursesSummary is id and name only. used for a searchbar
  const fetchCoursesSummary = async () => {
    try {
      const apiResponse = await $fetch<ApiResponse>(ApiSearch.Url, {
        method: 'POST',
        body: bodyRequestSummary.value
      })

      const data = apiResponse?.hits?.hits as CourseResponse[]
      coursesSummary.value =
        data?.map((r) => ({
          name: r._source.title,
          id: r._id
        })) || []
    } catch (e) {}
  }

  // removeFilter is a crud operation to update the filter tree to false. The reason we don't delete is that some
  // components need the field name to label, such as a multi select component
  const removeFilter = async (filter?: { field?: string; value?: string }) => {
    const { field, value } = filter || {}

    if (field) {
      if (['location', 'subjectArea'].includes(field)) {
        // location/subject area allow only one value, reset should clear
        filtersTree.value[field] = cloneDeep(defaultFilterTree[field])
      } else if (value) {
        // reset a single filter value to default
        filtersTree.value[field][value] = cloneDeep(
          defaultFilterTree[field][value]
        )
      } else {
        // reset a filter category to default
        filtersTree.value[field] = cloneDeep(defaultFilterTree[field])
      }

      // Only refresh results if the filter is not client sided.
      if (!clientFilterKeys.includes(field)) {
        await fetchCourses(true)
      }
    } else {
      // reset all filters
      filtersTree.value = cloneDeep(defaultFilterTree)
      await fetchCourses(true)
    }
  }

  // setCourseFilterObject is a crud operation to update the filterTree, which in turn automatically updates the mapped filters.
  // after updating the filterTree it updates the courses by fetching the data. Could potentially call separately
  const setCourseFilterObject = async (
    field: 'location' | 'subjectArea' | 'qualification' | 'courseType',
    event: { [value: string]: boolean } | undefined
  ) => {
    const tree = filtersTree.value
    if (event) {
      // force single-value select for location and subject area
      if (['location', 'subjectArea'].includes(field)) tree[field] = {}

      Object.keys(event).forEach((value) => {
        if (!tree[field]) tree[field] = {}
        tree[field][value] = event[value]
      })
    } else {
      tree[field] = {}
    }

    if (!clientFilterKeys.includes(field)) {
      await fetchCourses(true)
    }
  }

  // updateCourseFilter may require updating, this is for the searchbar summary search
  const updateCourseFilter = async (filterKey: 'campus', input: string) => {
    if (filterKey == 'campus') courseCampus.value = input
    bodyRequestSummary.value.params[filterKey] = input
    await fetchCourses(true)
  }

  // updateCourseQuery allows for user to type in a search and filter results.
  // it also fetches the courses. could be done separately.
  const updateCourseQuery = async (input?: string) => {
    queryString.value = input || ''
    await fetchCourses(true)
  }

  // updateCoursesSummaryQuery is same as updateCourseQuery but is just the name and id.
  // to be used for search bar, where detail is not required.
  // currently also fetches the courses with detail due to course search page
  const updateCourseSummaryQuery = async (input: string | undefined) => {
    // clear the summary results if no search input
    if (!input || input === '') {
      coursesSummary.value = []
      return
    }

    bodyRequestSummary.value.params.query_string = `${input}`
    queryString.value = input
    await fetchCoursesSummary()
  }

  // updatePagination to handle infinite scroll
  const updatePagination = (size: number, from: number) => {
    bodyRequest.value.params.from = from
    bodyRequest.value.params.size = size
  }

  const getActiveClassifications = () => {
    const activeFilters = filtersTree.value?.['courseType']
    if (!activeFilters) return []

    const courseTypes = Object.values(filtersTree.value['courseType'])

    return courseTypes
      .map((x, idx) => (x ? courseTypeClassifications[idx] : null))
      .filter((x) => x !== null)
  }

  const filteredCourses = computed(() => {
    let filtered: CourseDetails[] = courses.value

    const classifications = getActiveClassifications()

    if (classifications.length > 0) {
      filtered = filtered.filter(
        (x) => x.classification && classifications.includes(x.classification)
      )
    }

    filtered = filtered.sort((a, b) => {
      // Check if any of the items lack a classification.
      if (!a.classification || !b.classification) {
        return !a.classification ? -1 : b.classification ? 1 : 0
      }

      // Compare by the indices of the classification.
      const aidx = courseTypeClassifications.findIndex(
        (x) => x === a.classification
      )
      const bidx = courseTypeClassifications.findIndex(
        (x) => x === b.classification
      )

      return aidx - bidx
    })

    return filtered
  })

  return {
    // reactive variables
    courseSearchTotal,
    bodyRequest,
    breadcrumbs,
    courseCampus,
    courses,
    filteredCourses,
    coursesSummary,
    errorCoursesApi,
    filtersTree,
    loadingCourses,
    queryString,

    // computed
    mappedFilters,
    reachedEnd,

    // methods
    fetchSubjectAreasLocations,
    fetchCoursesSummary,
    fetchCourses,
    removeFilter,
    setCourseFilterObject,
    updateCourseQuery,
    updateCourseFilter,
    updateCourseSummaryQuery,
    updatePagination
  }
}
